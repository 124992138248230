import React from 'react';

import Layout, { LayoutMain } from '../../components/Layout';
import SEO from '../../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <LayoutMain>
      <div className="main__content">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </LayoutMain>
  </Layout>
);

export default NotFoundPage;
